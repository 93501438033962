<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow3.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>HOTFLOW THREE: A NEW ERA FOR REFLOW.</h2>
                  <br />
                  <h5>
                    With the HOTFLOW THREE, Ersa makes a clear statement for
                    even more quality in soldering. Absolutely unique selling
                    point: the SMART CONVECTION POWER UNIT (SCPU®). The
                    exclusively developed motor and control unit ensures an
                    optimized soldering profile and thus even better soldering
                    results. Individual zones can be controlled individually,
                    allowing perfect adaptation to the requirements of material
                    and solder paste.
                  </h5>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="mt-4">
                  <br /><br /><br /><br />

                  <h2>Incredible OEE thanks to three-stage cleaning system</h2>
                  <br />
                  <h5>
                    One of many highlights is the innovative cleaning system
                    with SMART ELEMENTS® filter for better handling, longer
                    service life and extended maintenance intervals. In the
                    preheating zone, residues are reliably collected, while in
                    the peak zone the SMART PYROLYSIS CLEANER is designed for
                    maximum removal of particle residues. In the cooling zone,
                    condensate deposits in particular are collected.
                  </h5>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/icredible.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/variable.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <br /><br /><br /><br />
                <div class="mt-4">
                  <h2>Variable transport system</h2>
                  <br />
                  <h5>
                    The HOTFLOW THREE, available with 16, 20 or 26 heating
                    zones, offers single-track and variable dual-track systems
                    for transporting boards up to 650 mm wide (single track) or
                    up to 2x 300 mm or 510 mm wide (dual track). All versions
                    feature center support, which can be optionally activated or
                    deactivated. The HOTFLOW THREE is also convincing when it
                    comes to maintenance - service for the cooling zone and
                    cleaning of the filter systems is simply carried out via the
                    front.
                  </h5>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="mt-4">
                  <br /><br /><br /><br />

                  <h2>HOTFLOW THREE - basic equipment</h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>SCPU®</h5>
                    </li><br>
                    <li>
                      <h5>SMART CONDENSATION UNIT</h5>
                    </li><br>
                    <li>
                      <h5>
                        Single Track – max working width 510 mm (650 mm
                        optional)
                      </h5>
                    </li><br>
                    <li>
                      <h5>Programmable width adjustment</h5>
                    </li><br>
                    <li>
                      <h5>Industry-PC with ERSASOFT 5</h5>
                    </li><br>
                  </ul>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/basic.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br />

            
            <br /><br />
            <div class="row container" style="font-size: 12px">
            <div class="col-3"></div>
            <div class="col-8">
            <h1><strong> Technical Highlights </strong></h1>
            <br><br>
              <div class="container-fluid">
                <ul class="container">
                  <li>
                    <h4>Optimal soldering quality, no matter how complex the assembly, no matter how demanding the components</h4>
                  </li>
                  <br>
                  <li>
                    <h4>SMART CONVECTION POWER UNIT (SCPU®)</h4>
                  </li><br>
                  <li>
                    <h4>
                     Higher productivity with lower/minimized energy consumption
                    </h4>
                  </li><br>
                  <li>
                    <h4>Longer maintenance intervalls due to innovative three-fold cleaning system SMART ELEMENTS®</h4>
                  </li><br>
                  <li>
                    <h4>SMART PYROLYSIS CLEANER</h4>
                  </li><br>
                  <li>
                    <h4>SMART CONDENSATION UNIT</h4>
                  </li><br>
                  <li>
                    <h4>Super maintenance friendly and easy to access components minimize downtimes</h4>
                  </li><br>
                </ul>
              </div>
              </div>
              <div class="col-1"></div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>